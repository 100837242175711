<template>
  <v-col :cols="cols">
    <v-combobox
      v-model="inputVal"
      :items="field.answers"
      :label="field.label"
      :item-text="field.itemtxt"
      :item-value="field.itemid"
      :disabled="field.disabled"
      :placeholder="field.placeholder"
      :menu-props="{ offsetY: true }"
      outlined
      dense
      :value="field.selected"
      class="selected"
      :multiple="fields.multiple"
      clearable
      :rules="[Empty]"
      :small-chips="fields.chips"
      v-on:change="HandleChange"
    >
      <template #item="{ item }" v-if="!field.multiple">
        <span class="labelStyle" v-if="field.itemtxt === 'name'">
          {{ item.name }}</span
        >
        <span class="labelStyle" v-else-if="field.itemtxt === 'description'">
          {{ item.description }}</span
        >
        <span class="labelStyle" v-else> {{ item }}</span>
      </template>
    </v-combobox>
  </v-col>
</template>
<script>
export default {
  name: "drop",
  props: ["fields", "cols", "song", "rules", "model"],
  data() {
    return {
      field: this.fields,

      none: [],
      Empty(value) {
        if (value instanceof Array && value.length == 0) {
          return "Campo Obrigatório.";
        }
        return !!value || "Campo Obrigatório";
      },
    };
  },
  methods: {
    HandleChange() {
      console.log("changind");
      this.$emit("HandleChange");
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.model;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped src="../../assets/css/SelectField.css"></style>
