import { render, staticRenderFns } from "./ComboBox.vue?vue&type=template&id=408f2775&scoped=true&"
import script from "./ComboBox.vue?vue&type=script&lang=js&"
export * from "./ComboBox.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/SelectField.css?vue&type=style&index=0&id=408f2775&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "408f2775",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
installComponents(component, {VCol,VCombobox})
